@import "../../../../sass_modules/colors.scss";

.header {
  width: 100%;
  height: 82px !important;
  background: $background_white !important;
  padding: 0px !important;

  .logo {
    width: 210px;
    height: 46px;
  }
  .header_container {
      justify-content: space-between;
      margin: 0 26px;
  }

  .left_menu {
    align-items: center;
    justify-content: center left;
    display: flex;

    svg {
      display: block;
      margin-top: 4px;
    }
  }

  .right_menu {
    height: 82px;
    display: flex;
    align-items: center;

    .user_email {
      margin-left: 8px;
      margin-right: 6px;
      font-size: 16px;
      line-height: 21.25px;
      color: #1C0B2C;
    }

    .btn_menu {
      float: right;
      height: 100%;
      font-size: 2rem;
      align-items: center;
      justify-content: center;
      display: flex;
      border: 0;
      background: transparent;
      // width: 82px;
      cursor: pointer;
      padding: 6px 0 0 0;

      svg {
        path {
          fill: $text_color_2;
          -webkit-transition: all 300ms ease;
          -moz-transition: all 300ms ease;
          -ms-transition: all 300ms ease;
          -o-transition: all 300ms ease;
          transition: all 300ms ease;
        }
      }

      &:hover {
        svg {
          path {
            fill: $blue_1;
          }
        }
      }
    }
  }
}

.modal_menu {
  :global(.ant-drawer-content-wrapper) {
    width: 300px !important;
  }

  :global(.ant-drawer-content) {
    border-radius: 4px;
  }

  :global(.ant-drawer-header) {
    height: 82px;
    border: 0;

    button {
      position: absolute;
      right: 14px;
      top: 21px;
      // background: red;
      margin-right: 0;
    }

    :global(.ant-drawer-title) {
      font-family: "FuturaBold", sans-serif;
      font-size: 26px;
      line-height: 35px;
    }

    :global(.anticon-close) {
      width: 40px;
      height: 40px;
      border: 2px solid rgba(28, 11, 44, 0.1);
      box-sizing: border-box;
      position: relative;
      border-radius: 20px;
      cursor: pointer;
      background: transparent;
      -webkit-transition: all 300ms ease;
      -moz-transition: all 300ms ease;
      -ms-transition: all 300ms ease;
      -o-transition: all 300ms ease;
      transition: all 300ms ease;

      svg {
        display: none;
      }
      &:after {
        content: url("../../../../images/close_cross.svg");
        display: block;
        width: 10px;
        height: 10px;
        position: absolute;
        left: 8px;
        top: 8px;
      }
      &:hover {
        background: $blue_1;
        border-color: $blue_1;

        &:after {
          filter: brightness(5);
        }
      }
    }
  }

  :global(.ant-drawer-body) {
    padding: 0;

    li {
      margin: 0 !important;
      padding: 0;
      height: auto;

      &:hover {
        background: $menu_hover_background;
      }

      &:active {
        -webkit-box-shadow: inset 0 0 8px 0 rgba(0, 0, 0, 0.2);
        box-shadow: inset 0 0 8px 0 rgba(0, 0, 0, 0.2);
      }

      &:focus {
        background: $menu_hover_background;
      }

      span {
        width: 100%;
        display: block;

        a,
        button {
          padding: 13px 26px;
          width: 100%;
          line-height: 24px;
          font-size: 18px;
          color: $text_color_3;
          font-family: "Futura", sans-serif;
          display: flex;
          align-items: center;
          justify-content: left;
          text-align: left;
        }

        button {
          height: auto;
          border: 0;

          span {
            display: inline-block;
          }
        }
      }
    }

    .menu_ico {
      display: block;
      float: left;
      width: 30px;
      height: auto;
      margin-right: 18px;
    }

    .menu_ico_reset_password {
      margin-left: -2px;
      margin-right: 16px;
    }
  }

  :global(.ant-drawer-mask) {
    background: rgba(28, 11, 44, 0.4);
    backdrop-filter: blur(8px);
  }
}

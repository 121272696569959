@import "../../../../sass_modules/fonts.scss";

.modal_content {
  text-align: center;

  svg {
    fill: yellowgreen;
    height: 65px;
    width: 65px;
  }

  ._modal_title {
    @extend .modal_title_font;

    margin-top: 26.5px;
    margin-bottom: 26.5px;
  }

  ._modal_subtitle {
    @extend .modal_subtitle_font;

    text-align: left;
    padding-left: 120px;
    margin-bottom: 40px;
  }

  .grid_col_2 {
    margin-left: auto;
    margin-right: auto;
    max-width: 382px;
    column-gap: 14px;
  }

  button {
    height: 50px;
    border-radius: 8px;
  }
}

@import "../../../../../sass_modules/colors.scss";

.input_form, .ant-select, .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 52px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #a3b0bf !important;
    border-radius: 8px !important;
    font-family: "Futura", sans-serif;
    line-height: 22px !important;
    font-size: 16px !important;
    margin-bottom: 15px;

    &::-webkit-input-placeholder {
        color: #a3b0bf;
    }
    &::-moz-placeholder {
        color: #a3b0bf;
    }
    &:-ms-input-placeholder {
        color: #a3b0bf;
    }
    &:-moz-placeholder {
        color: #a3b0bf;
    }
    &:not(:placeholder-shown) {
        border-color: #1C0B2C;
    }
}

.input_form_label {
    font-family: "Futura", sans-serif;
    line-height: 21px;
    font-size: 16px;
    display: block;
    margin-bottom: 5px;
}

@import "./colors.scss";

.common_submit_button {
    @extend .button_font;

    height: 60px;
    border-radius: 6px;
    border: unset;
    width: 100%;

    &:disabled {
        background: rgb(163, 176, 191, 0.3);
        color: #a3b0bf !important;
    }
}

.common_cancel_button {
    @extend .button_font;

    height: 60px;
    border-radius: 6px;
    border: unset !important;
    width: 100%;
    background: rgb(251, 163, 56, 0.1) !important;
    color: $text_color_4 !important;

    &:hover {
        background: rgb(251, 163, 56, 0.2) !important;
    }
}

.common_add_new_button {
    @extend .button_font;

    height: 50px !important;
    border-radius: 6px !important;
    border: unset;
    color: #ffffff !important;
    border: unset !important;
    background: #fba338 !important;
    min-width: 184px;
}

.common_link_button {
    @extend .button_font;

    padding: 0px !important;
    background: unset;
    border: unset;
    color: $text_color_4 !important;

    &:hover {
        color: $text_color_4 !important;
        background: unset !important;
    }
}

.common_unlock_button {
    border-radius: 8px !important;
    font-size: 10px;
}

.common_apply_filters_button {
    width: 184px;
    height: 49px;
    border-radius: 8px;
}

.common_previous_button {
    @extend .button_font;

    height: 50px !important;
    border-radius: 6px !important;
    border: unset;
    color: #fba338 !important;
    border: unset !important;
    background: rgba(251, 163, 56, 0.1);
    min-width: 184px;

    &:hover {
        background: rgba(251, 163, 56, 0.3);
    }
}

.common_next_button {
    @extend .button_font;

    height: 50px !important;
    border-radius: 6px !important;
    min-width: 184px;
}
.button_modal {
    height: 52px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #a3b0bf !important;
    border-radius: 8px !important;
    font-family: "Futura", sans-serif;
    line-height: 22px !important;
    font-size: 16px !important;
    margin-bottom: 15px;
    text-align: start;

    &::-webkit-input-placeholder {
        color: #a3b0bf;
    }
    &::-moz-placeholder {
        color: #a3b0bf;
    }
    &:-ms-input-placeholder {
        color: #a3b0bf;
    }
    &:-moz-placeholder {
        color: #a3b0bf;
    }
    &:not(:placeholder-shown) {
        border-color: #1C0B2C;
    }
}

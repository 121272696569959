.common_grid {
    display: grid;
    column-gap: 10px;
}

.grid_col_2 {
    @extend .common_grid;
    grid-template-columns: repeat(2, 1fr);
}

.grid_col_3 {
    @extend .common_grid;
    grid-template-columns: repeat(3, 1fr);
}

.grid_col_4 {
    @extend .common_grid;
    grid-template-columns: repeat(4, 1fr);
}

.grid_col_5 {
    @extend .common_grid;
    grid-template-columns: repeat(5, 1fr);
}

.grid_col_6 {
    @extend .common_grid;
    grid-template-columns: repeat(6, 1fr);
}

.grid_col_7 {
    @extend .common_grid;
    grid-template-columns: repeat(7, 1fr);
}

.width_100_percent {
    width: 100% !important;
}

.custom_descriptions_style {
    .ant-descriptions-header {
        margin-bottom: 6px !important;
    }

    .common{
        font-size: 10px !important;
        padding: 4px 6px !important;
    }

    .ant-descriptions-title{
        font-size: 10px !important;
    }

    .ant-descriptions-item-label {
       @extend .common;
    }

    .ant-descriptions-item-content{
       @extend .common;
    }
}

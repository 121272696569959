@import "../../../../sass_modules/fonts.scss";

.content {
  width: 100%;

  border-radius: 14px;
  position: relative;
  display: flex;
  justify-content: space-between;
}
.contentForm {
  background: white;
  width: 100%;
  min-width: 757px;
  min-height: 711px;
  padding: 32px 24px 24px 24px;
  margin-right: 32px;
  margin-bottom: 30px;
  border-radius: 14px;
}
.content_wrapper {
  display: flex;
  justify-content: left;
}
.select_wrapper {
  width: 343px;
  height: 532px;
  border: 1px solid #a3b0bf80;
  padding: 16px;
  padding-bottom: 0;
  padding-right: 4px;
  border-radius: 16px;
}
.search_element_style {
  border: none !important;
  padding: 2px !important;
  width: 320px !important;
}
.divider {
  margin: 16px 0 !important;
}
.scrollbar {
  max-height: 370px;
  overflow-y: auto;
  width: 100%;
}

.scrollbar::-webkit-scrollbar {
  width: 6px;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 16px;
  background: rgba(209, 215, 223, 0.6);
  height: 105px;
}

.bordered_content {
  display: flex;
  flex-direction: column;
  padding: 24px;
  border: 1px solid rgba(163, 176, 191, 0.4);
  border-radius: 16px;
  min-width: 317px;
}

@import "../../../../sass_modules/fonts.scss";

.modal_content {
  ._modal_title {
    @extend .modal_title_font;

    margin-top: 26.5px;
  }

  .grid_col_2 {
    margin-left: auto;
    margin-right: auto;
    max-width: 382px;
    column-gap: 14px;
  }
}
 .switch_style > div:first-child {
      display: flex;
      align-items: center;
      margin-right: 5px;
  }

@import "~antd/dist/antd.css";
@import "./sass_modules/all.scss";

body {
  margin: 0;
  padding: 0;
  font-family: 'Futura';
}

.layout {
  min-height: 100vh;
  width: 100vw;
}

.content {
  color: $gray_13;
  background: $gray_14;
  padding: 30px;
  height: 105vh;
}

.content_1 {
  height: 100vh;
}

.margin_bottom {
  margin-bottom: 10px;
}

.margin_bottom_20{
  margin-bottom: 20px !important;
}

.margin_rigth {
  margin-right: 10px;
}

/*#region --- Loader --- */

.fixed_loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(#d9d9d9, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

/*#endregion --- Loader --- */
.common_form_1 {
  width: 525px;
  height: 100vh;
  float: left;
  background-color: $background_2;
  position: relative;

  ._form_content {
    margin: 128px 56px auto 56px !important;
  }

  .__back_button {
    position: absolute;
    top: 64px;
    left: 56px;
    cursor: pointer;
  }

  .__form_title {
    @extend .form_title_font;

    margin-bottom: 16px;
  }

  .__form_subtitle {
    @extend .form_subtitle_font;

    margin-bottom: 39px;
    width: 374px;
  }

  .__form_subtitle_1 {
    @extend .form_subtitle_font;

    color: #1C0B2C;

    a {
      color: orange;
      font-weight: 700;
    }
  }

  .__submit_button {
    @extend .common_submit_button;

    margin-bottom: 32px;
  }

  .__custom_input {
    height: 52px;
    border-radius: 8px;
  }

  .__custom_input_number {
    width: 100% !important;
    border-radius: 8px;

    input {
      height: 52px;
    }
  }

  .ant-form-item .ant-form-item-label > label {
    @extend .form_item_label_font;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
}

/*#region --- Filters common styles --- */

.common_date_picker {
  border-radius: 8px;
  width: 138px;
  height: 50px;
  margin-bottom: 7px;
}

/*#endregion --- Filters common styles --- */

.column_description {
  font-family: "Futura", sans-serif;
  font-size: 24px;
  line-height: 31px;
  color: #1C0B2C;
  margin-bottom: 19px;
}

////////////////////////@at-root

.modal_input_number {
  .ant-input-number-input {
    height: 52px;
  }
}

.section_description {
  font-family: "Futura", sans-serif;
  font-size: 18px;
  line-height: 24px;
  color: #1C0B2C;
}
@import "./colors.scss";
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,300;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@300;400;500;600&family=Merriweather+Sans:wght@400;500;800&display=swap");
input, button, select, optgroup, textarea {
  font-family: "Futura", sans-serif;
}
.ant-btn-primary {
  background-color: #458FE6;
}
.page_title_font {
  font-family: "FuturaBold", sans-serif;
  font-size: 24em;
  line-height: 34em;
  letter-spacing: 0em;
  color: $gray_13;
}

.form_title_font {
  font-family: "FuturaBold";
  font-size: 32px;
  font-style: normal;
  line-height: 110%;
  letter-spacing: 0em;
  color: $text_color_3;
}

.form_subtitle_font {
  font-family: "Futura";
  font-size: 16px;
  font-style: normal;
  line-height: 24.4px;
  letter-spacing: 0px;
  color: #a3b0bf;
}

.modal_title_font {
  font-family: "FuturaBold", sans-serif;
  font-size: 20px;
  line-height: 26.5px;
  letter-spacing: 0px;
  color: $gray_13;
}

.modal_subtitle_font {
  font-family: "Futura";
  font-size: 16px;
  line-height: 24.4px;
  color: #a3b0bf;
}

.form_item_label_font, .ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label{
  font-family: "Futura";
  font-size: 16px;
  line-height: 21.25px;
  letter-spacing: 0px;
  color: #1C0B2C;
  margin-bottom: 5px;
}

.button_font {
  font-family: "FuturaBold" !important;
  font-size: 16px !important;
  font-style: normal !important;
  line-height: 21.25px !important;
  letter-spacing: 0px !important;
  color: white;
}

.statistics_title_font {
  font-family: "Futura";
  font-style: normal;
  font-weight: 100;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
  opacity: 0.6;
}

.statistics_value_font {
  font-family: "FuturaBold", sans-serif;
  font-style: normal;
  font-size: 24px;
  line-height: 24px;
  color: #ffffff;
}

.statistics_chart_title_font {
  font-family: "FuturaBold", sans-serif;
  font-style: normal;
  font-size: 18px;
  line-height: 18px;
  color: #333333;
}

.breadcrumb_font {
  font-family: "Futura", sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 21px;
  color: #458fe6;
}

.column_description_font {
  font-family: "Futura", sans-serif;
  font-size: 24px;
  line-height: 31px;
  color: #1C0B2C;
}

.ant-steps-vertical {
    flex-shrink: 2;
    margin-top: 5px;
}

// .ant-steps-navigation {
//     margin-left: 16px;
//     text-align: left !important;
//     margin-bottom: 16px;
// }

// .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
//     text-align: left;
// }
// .ant-steps-item {
//   display: flex;
//   flex: initial;
//   margin-right: 50px;
// }

.ant-steps-item-icon .ant-steps-icon {
top: -3.5px;
} 

.ant-steps-item-icon {
    height: 24px !important;
    width: 24px !important;
    border: unset !important;
    background-color: #458fe6 !important;
    margin-right: 8px !important;
    margin-top: -3px;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: #458fe6 !important;
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title,
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: #A3B0BF !important;
}
.ant-steps-item-title {
    font-size: 14px;
    line-height: 19px !important;
    font-family: 'Futura';
}

.ant-steps-item-wait .ant-steps-item-icon {
    background-color: #a3b0bf !important;   
    border: none !important;
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: white !important;

}

.ant-steps-item-finish .ant-steps-item-icon {
    background-color: white !important;
    border: 1px solid #458FE6 !important;
    .ant-steps-icon {
        color: #458fe6;
    }
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    border-color: #458fe6 !important;
}

.ant-steps-item-active::before {
    display: none !important;
}
// .ant-steps-navigation .ant-steps-item::after {
//     margin-left: 17px;
//     top: 59%;
//     width: 9px;
//     height: 9px;
// }
.ant-steps-vertical > .ant-steps-item {
    margin-bottom:  48px;
    flex: none;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
    min-height: auto;
}

.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
position: absolute;
width: 40px;
height: 0px;
left: -24px;
top: 42px;

/* Light Blue */
border: 1px solid #A3B0BF;
transform: rotate(90deg);
}

.ant-modal-content {
    border-radius: 12px !important;
}
.ant-modal-header {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}
.select-modal {
    .ant-modal-footer {
        text-align: center;
    }
}
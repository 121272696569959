@import "../../../sass_modules/fonts.scss";

.modal_content {
  text-align: center;

  ._modal_title {
    @extend .modal_title_font;

    margin-top: 26.5px;
  }

  .grid_col_2 {
    margin-left: auto;
    margin-right: auto;
    max-width: 382px;
    column-gap: 14px;
  }

  button {
    height: 50px;
    border-radius: 8px;
  }
}

.ant-input-number-input {
  height: 52px !important;
}

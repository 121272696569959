@import "../../../../sass_modules/colors.scss";

.content {
  width: calc(100% - 525px);
  height: 100vh;
  min-height: 643px;
  background: $background_3;
  display: flex;
  justify-content: center;

  ._svg {
    margin: auto;
    display: block;
  }
}

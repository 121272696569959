@import "../../../../sass_modules/fonts.scss";
@import "../../../../sass_modules/common_buttons.scss";

.add_new_button {
  @extend .common_add_new_button;

  margin-bottom: 20px;
  padding-left: 17px !important;
  padding-right: 12px !important;

  :global(.ant-image) {
    margin-right: 15px;
  }
}
@import "./fonts.scss";

.ant-breadcrumb {
    margin-bottom: 15.5px;
    
    a {
        @extend .breadcrumb_font;
    }
}

.ant-breadcrumb-link {
    @extend .breadcrumb_font;
}

.ant-breadcrumb-separator {
    @extend .breadcrumb_font;
}

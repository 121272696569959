.transfer-form-wrapper {
  .ant-input-number {
    .ant-input-number-handler-wrap {
      display: none !important;
    }
  }
  
  .ant-input-number,
  .ant-input-number-input {
    height: 34px !important;
    border-radius: 0 !important;
    line-height: 1.5715; // Matches AntD Input
    padding: 4px 11px;   // Matches Input padding
  }
  
  // Force AntD Select to match input height (34px)
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 34px !important;
    padding: 0 11px !important;
    display: flex;
    align-items: center;
    border-radius: 0 !important; // Optional: match input's corners
  }
}

// Background
$background_1: #d0d5db;
$background_2: #ffffff;
$background_3: #f5f8fc;
$background_4: #F5F8FC;

// Colors
$gray_1: #ffffff;
$gray_2: #fafafa;
$gray_3: #f5f5f5;
$gray_4: #f0f0f0;
$gray_5: #d9d9d9;
$gray_6: #bfbfbf;
$gray_7: #8c8c8c;
$gray_8: #595959;
$gray_9: #434343;
$gray_10: #262626;
$gray_11: #1f1f1f;
$gray_12: #141414;
$gray_13: #000000;
$gray_14: #F5F6F7;

$red_1: #fff1f0;
$red_2: #ffccc7;
$red_3: #ffa39e;
$red_4: #ff7875;
$red_5: #ff4d4f;
$red_6: #f5222d;
$red_7: #cf1322;
$red_8: #a8071a;
$red_9: #820014;
$red_10: #5c0011;

$blue_1: #458fe6;

// header
$background_white: #ffffff;
$text_color_1: #fff;
$text_color_2: #000000;
$text_color_3: #1c0b2c;
$text_color_4: #fba338;
$menu_hover_background: rgba(163, 176, 191, 0.2);

// left menu
$main_menu_btn_background: rgba(163, 176, 191, 0.1);
$text_color_menu: rgba(163, 176, 191, 1);
$orange_menu: #fba338;
$blue_btn: rgba(69, 143, 230, 1);

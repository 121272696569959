.ant-input-affix-wrapper,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    height: 52px;
    border-radius: 8px;
}

.ant-input-password-icon.anticon {
    font-size: 20px;
}
.ant-form-item-label > label,
.ant-form label {
    font-size: 16px;
}
.ant-checkbox-wrapper {
    font-size: 14px !important;
    line-height: 19px;
}
.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
    padding: 0;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
}
.ant-form-item .ant-select,
.ant-form-item {
    border: none;
}
.ant-form-item {
    margin-bottom: 15px;
}
.ant-input {
    font-size: 16px !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 8px;
    color: #1C0B2C;
    background-color: white;
    min-width: 138px;
    padding-top: 9px;
    height: 52px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #a3b0bf !important;
    font-family: "Futura", sans-serif;
    line-height: 22px !important;
    font-size: 16px !important;
}
.ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 22px !important;
    padding-top: 6px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item {
    padding-top: 0;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    margin-top: 9px;
}

.ant-input-number {
    height: 52px;
    border-color: #1C0B2C;
    border-radius: 8px;
}

.ant-input-number-lg input {
    margin-top: 5px;
}

.ant-pagination .ant-select-selection-item {
    font-size: 14px !important;
}
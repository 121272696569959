@import "../../../../sass_modules/colors.scss";
@import "../../../../sass_modules/fonts.scss";

.page_title_style {
  @extend .page_title_font;

  width: 100%;
  line-height: 34.5px;
  font-size: 26px;
  margin-bottom: 23px;
}

.ant-table {
  color: #1C0B2C;
  border-radius: 14px;

  a, a > button > span {
    color: #458fe6;
    line-height: 16px;
  }
}

.ant-table.ant-table-small {
  font-size: 12px;
}
.ant-table.ant-table-bordered > .ant-table-container,
.ant-table-content,
.ant-table.ant-table-middle {
  border-radius: 14px;
}
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > thead
  > tr
  > th:last-child {
  border-top-right-radius: 14px;
}
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > thead
  > tr
  > th:first-child {
  border-top-left-radius: 14px;
}
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr:last-child
  > td:last-child {
  border-bottom-right-radius: 14px;
}

.ant-table.ant-table-middle .ant-table-tbody > tr > td,
.ant-table.ant-table-middle .ant-table-thead > tr > th,
.ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table.ant-table-small .ant-table-thead > tr > th {
  padding-left: 20px;
  padding-right: 10px;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding-left: 20px;
  padding-right: 10px;
}

.transaction-table {
  .ant-table-column-sorters, th {
    font-size: 12px;
  }

  .ant-table {
    font-size: 12px;
  }
}
.antifraud-table {
  .ant-table-column-sorters, td:not(:last-child), th:not(:last-child) {
    border-right: none !important;
  }
  .ant-table-column-sorters, th, td {
    background-color: transparent !important;
  }
  .ant-table-column-sorters, th {
    color: #A3B0BF !important;
    background-color: transparent !important;
  }
}

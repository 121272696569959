.flex {
  display: flex;
}
.content-wrapper {
  background: #ffffff;
  width: 100%;
  min-height: 500px;
  padding: 14px 24px 24px 24px;
  border-radius: 14px;
  position: relative;

  .action-buttons {
    position: absolute;
     bottom: 0; 
     left: 24
  }
}
.switch_style > div:first-child {
  display: flex;
  align-items: center;
  margin-right: 5px;
}
// height on multiple selection 
.multi_select > div {
  height: auto !important;
}
@import "../../../sass_modules/fonts.scss";

.common_border_radius {
  border-radius: 14px;
}

.common_statistics_element {
  @extend .common_border_radius;

  flex-grow: 1;
  height: 126px;

  img {
    padding-top: 17px !important;
    padding-left: 16px !important;
  }

  .__title {
    @extend .statistics_title_font;

    padding-left: 16px;
    padding-top: 7px;
    padding-bottom: 3px;
  }

  .__value {
    @extend .statistics_value_font;

    padding-left: 16px;
  }
}

.statistics_container {
  display: flex;
  width: 100%;
  column-gap: 18px;
  margin-top: 24px;

  ._total_sales {
    @extend .common_statistics_element;

    background: linear-gradient(78.02deg, #ad51d0 1.96%, #d477f8 103.55%);
  }

  ._total_commissions {
    @extend .common_statistics_element;

    background: linear-gradient(78.02deg, #458fe6 1.96%, #72b1fc 103.55%);
  }

  ._no_of_transactions {
    @extend .common_statistics_element;

    background: linear-gradient(78.02deg, #fba338 1.96%, #ffc57e 103.55%);
  }

  ._conversion_rate {
    @extend .common_statistics_element;

    background: linear-gradient(78.02deg, #27ae60 1.96%, #3ede82 103.55%);
  }

  ._new_user_sign_up {
    @extend .common_statistics_element;

    background: linear-gradient(78.02deg, #354fd5 1.96%, #455fe6 103.55%);
  }

  ._kyc_drop_off {
    @extend .common_statistics_element;

    background: linear-gradient(78.02deg, #dc3939 1.96%, #eb5757 103.55%);
  }
}

.charts_containter {
  @extend .common_border_radius;
  background-color: #ffffff;
  height: 403px;
  margin-top: 16px;

  .__title {
    @extend .statistics_chart_title_font;
    padding-top: 25px;
    padding-left: 20px;
  }
}
@import "../../../../sass_modules/colors.scss";

.main_menu {
  padding: 30px 14px 14px 10px;
  background: $background_white !important;

  :global(.ant-menu-title-content) {
    height: 54px !important;
    font-family: "Futura", sans-serif;
    line-height: 21px;
    font-size: 16px;
    display: flex;
    margin: 0 0 10px 0 !important;
    background: $main_menu_btn_background;
    border-radius: 8px;
    color: $text_color_menu;
    width: 100%;
    align-items: center;
    justify-content: left;
    padding: 0 10px;

    a {
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: left;
      display: flex;
    }
  }
  :global(.ant-menu-item-selected) {
    background: transparent !important;

    &::after {
      display: none;
    }

    :global(.ant-menu-title-content) {
      background: $blue_btn;
      a {
        color: #fff;
      }
    }
  }
  :global(.ant-menu-submenu-open) {
    :global(.ant-menu-submenu-title) {
      span {
        background: $blue_btn;
        color: #fff;
      }
      i {
        filter: brightness(2);
        &:before {
          color: #fff;
        }
      }
    }
    :global(.ant-menu-submenu-arrow) {
      &:before {
        content: url("../../../../images/arrow-right.svg");
        right: -5px;
        top: -12px;
        transform: rotate(90deg) translateY(-2.5px) !important;
      }
    }
  }
  :global(.ant-menu-submenu-arrow) {
    &:before {
      content: url("../../../../images/arrow-right.svg");
      background-color: transparent;
      height: 24px;
      width: 24px;
      transform: rotate(0deg) translateY(-2.5px) !important;
      right: -5px;
      top: -14px;
    }
    &:after {
      display: none;
    }
  }
  :global(.ant-menu-sub) {
    background: transparent !important;
    li {
      padding: 0 !important;
      height: auto;
      position: relative;
    }
    Li:last-of-type {
      :global(.ant-menu-title-content) {
        margin-bottom: 10px !important;
      }
      svg {
        bottom: 25px;
      }
    }
    :global(.ant-menu-item-selected) {
      a {
        color: $orange_menu !important;
      }
      svg {
        path {
          fill: $orange_menu !important;
        }
      }
    }
    :global(.ant-menu-item-icon) {
      position: absolute;
    }

    :global(.ant-menu-title-content) {
      background: transparent;
      padding-left: 40px;
      margin-bottom: 0 !important;
    }

    svg {
      width: 24px;
      height: 24px;
      left: 10px;
    }
  }
  :global(.ant-menu-item) {
    margin: 0 !important;
    padding: 0 !important;
    height: auto !important;

    &:active {
      background: transparent;
    }
  }

  li {
    padding: 0 !important;
    height: auto;

    div {
      padding: 0 !important;
      margin: 0 !important;
      height: auto !important;
    }
    a {
      color: $text_color_menu;
      height: 54px !important;
      display: block;
    }
  }
}
